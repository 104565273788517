<template>
  <a-row :gutter="[30, 0]">
    <a-col :span="24">
      <a-card class="filter-card" bordered>
        <div
          class="filter-container"
          :class="{ expanded: showFilters }"
          ref="filterContainer"
        >
          <a-row :gutter="[8, 16]">
            <!-- Main Filters Section -->
            <a-col :span="6">
              <a-select
                id="analytics-trace-select-task-filter"
                ref="analytics_trace_task_select"
                class="w-100"
                :value="selectedTask"
                show-search
                placeholder="Select Operation"
                :options="taskOptions"
                :loading="isFetchingTasks"
                :filter-option="filterOption"
                :get-popup-container="getPopupContainer"
                @change="handleTaskSelect"
                size="small"
              />
            </a-col>

            <!-- Date Range Picker -->
            <!-- <a-col :span="6">
              <a-range-picker>
                <template #renderExtraFooter>extra footer</template>
              </a-range-picker>
             </a-col> -->
            <a-col :span="3">
              <a-date-picker
                id="analytics-trace-date-range-filter"
                v-model:value="dateRange[0]"
                class="w-100"
                show-time
                placeholder="Start date"
                :get-popup-container="getPopupContainer"
                :disabled-date="disabledStartDate"
                :show-now="false"
                size="small"
              >
                <template #renderExtraFooter>
                  <div class="advance-filter-date-range-footer">
                    <a-typography-text
                      type="success"
                      class="mx-2"
                      style="cursor: pointer; color: #40a9ff"
                      @click="handleSelectDateRange(2)"
                    >
                      One Day
                    </a-typography-text>

                    <a-typography-text
                      type="success"
                      style="cursor: pointer; color: #40a9ff"
                      class=""
                      @click="handleSelectDateRange(1)"
                    >
                      One Week
                    </a-typography-text>

                    <a-typography-text
                      type="success"
                      class="mx-2"
                      style="cursor: pointer; color: #40a9ff"
                      @click="handleSelectDateRange(3)"
                    >
                      One Month
                    </a-typography-text>
                  </div>
                </template>
              </a-date-picker>
            </a-col>

            <a-col :span="3">
              <a-date-picker
                id="analytics-trace-date-range-filter"
                v-model:value="dateRange[1]"
                class="w-100"
                show-time
                placeholder="End date"
                :get-popup-container="getPopupContainer"
                :disabled-date="disabledEndDate"
                size="small"
                :show-now="false"
              >
                <template #renderExtraFooter>
                  <div class="advance-filter-date-range-footer">
                    <a-typography-text
                      type="success"
                      class="mx-2"
                      style="cursor: pointer; color: #40a9ff"
                      @click="handleSelectDateRange(2)"
                    >
                      One Day
                    </a-typography-text>

                    <a-typography-text
                      type="success"
                      style="cursor: pointer; color: #40a9ff"
                      class=""
                      @click="handleSelectDateRange(1)"
                    >
                      One Week
                    </a-typography-text>

                    <a-typography-text
                      type="success"
                      class="mx-2"
                      style="cursor: pointer; color: #40a9ff"
                      @click="handleSelectDateRange(3)"
                    >
                      One Month
                    </a-typography-text>
                  </div>
                </template>
              </a-date-picker>
            </a-col>

            <!-- Update Button -->
            <a-col :span="3">
              <a-button
                id="analytics-trace-update-btn"
                type="primary"
                block
                :disabled="fetchingTasks"
                :loading="isFetchingCycles"
                size="small"
                @click="getData()"
              >
                <sync-outlined /> Update
              </a-button>
            </a-col>
            <a-col :span="3">
              <a-button
                v-if="!isNoDataAvailable && isAnalytics"
                block
                @click="comparingAnalytics"
                type="primary"
                size="small"
              >
                {{ showCompareAnalytics ? 'Close Comparison' : 'Compare' }}
                <!-- <template #icon>
                  <CloseCircleOutlined v-if="showCompareAnalytics" />
                  <DiffOutlined v-else />
                </template> -->
              </a-button>
            </a-col>

            <!-- Toggle Advanced Filters Button -->
            <a-col :span="6">
              <div class="d-flex justify-content-end">
                <a-button
                  id="analytics-trace-advance-filter-toggle-btn"
                  type="default"
                  :loading="isFetchingCycles"
                  size="small"
                  @click="toggleAdvanceFilter"
                >
                  {{ showFilters ? 'Hide ' : 'Show ' }} Advanced Filters
                </a-button>
              </div>
            </a-col>
          </a-row>

          <!-- Advanced Filters Section -->
          <div class="advanced-filters mt-5">
            <a-row :gutter="[8, 16]">
              <!-- First Group -->
              <a-col :span="12">
                <a-row :gutter="[8, 16]">
                  <!-- Device Selector -->
                  <a-col :span="16">
                    <a-select
                      id="analytics-trace-select-device-filter"
                      v-model:value="selectedDevices"
                      mode="multiple"
                      class="w-100"
                      placeholder="Select Device(s)"
                      :options="deviceOptions"
                      :loading="isFetchingDevices"
                      :filter-option="filterOption"
                      :get-popup-container="getPopupContainer"
                      :max-tag-count="1"
                      size="small"
                    />
                  </a-col>

                  <!-- Select All Devices Button -->
                  <a-col :span="8">
                    <a-button block @click="handleSelectAll" size="small">
                      {{ isDevicesSelected ? 'Unselect all' : 'Select all' }}
                    </a-button>
                  </a-col>
                </a-row>
              </a-col>

              <!-- Select MetaData -->
              <a-col :span="12">
                <a-row :gutter="[8, 16]">
                  <!-- Steps Selector -->
                  <a-col :span="12">
                    <a-select
                      id="analytics-trace-select-meta-data-filter"
                      v-model:value="selectedMetaData"
                      class="w-100"
                      placeholder="Select Meta Data"
                      :options="metaDataOptions"
                      :loading="isFetchingMetaData"
                      :filter-option="filterOption"
                      :get-popup-container="getPopupContainer"
                      :max-tag-count="1"
                      size="small"
                    />
                  </a-col>

                  <!-- Select All Steps Button -->
                  <a-col :span="12">
                    <a-input
                      v-model:value="metaDataValue"
                      placeholder="Enter Meta Value"
                      class="w-100"
                      size="small"
                    />
                  </a-col>
                </a-row>
              </a-col>

              <!-- Third Group -->
              <a-col :span="12">
                <a-row :gutter="[8, 16]">
                  <!-- Cycle Range Input -->
                  <a-col v-if="showDurationInput" :span="24">
                    <a-input-group compact>
                      <a-input
                        v-model:value="durationStartTime"
                        addon-before="Cycle Range:"
                        type="number"
                        placeholder="Min Time (s)"
                        style="width: 55%"
                        size="small"
                      />
                      <a-input
                        v-model:value="durationEndTime"
                        type="number"
                        placeholder="Max Time (s)"
                        style="width: 45%"
                        size="small"
                      >
                        <template #addonAfter>
                          <close-circle-outlined
                            v-if="durationStartTime || durationEndTime"
                            @click="durationStartTime = durationEndTime = null"
                          />
                          <clock-circle-outlined v-else />
                        </template>
                      </a-input>
                    </a-input-group>
                  </a-col>
                </a-row>
              </a-col>

              <!-- Tags Group -->
              <a-col :span="12">
                <a-row :gutter="[8, 16]">
                  <!-- Steps Selector -->
                  <a-col v-if="showDurationInput" :span="16">
                    <a-select
                      id="analytics-trace-select-steps-filter"
                      v-model:value="selectedTags"
                      mode="multiple"
                      class="w-100"
                      placeholder="Select Tags"
                      :filter-option="filterOption"
                      :options="organizationTagOptions"
                      :get-popup-container="getPopupContainer"
                      :max-tag-count="2"
                      size="small"
                      @change="handleTagSelect"
                    />
                  </a-col>

                  <!-- Select All Steps Button -->
                  <a-col v-if="showDurationInput" :span="8">
                    <a-button block @click="handleSelectAllTags" size="small">
                      {{ isAllTagsSelected ? 'Unselect all' : 'Select all' }}
                    </a-button>
                  </a-col>
                </a-row>
              </a-col>

              <!-- Forth Group -->
              <a-col
                class="dependent-filters"
                v-if="selectedTask && showStepsFilter"
                :span="12"
              >
                <a-row :gutter="[8, 16]">
                  <!-- Steps Selector -->
                  <a-col :span="16">
                    <a-select
                      id="analytics-trace-select-steps-filter"
                      v-model:value="selectedSteps"
                      mode="multiple"
                      class="w-100"
                      placeholder="Filter cycles with these missed steps"
                      :filter-option="filterOption"
                      :options="stepFilterOptions"
                      :get-popup-container="getPopupContainer"
                      :max-tag-count="2"
                      size="small"
                    />
                  </a-col>

                  <!-- v-if="selectedTask && showStepsFilter" -->
                  <!-- Select All Steps Button -->
                  <a-col :span="8">
                    <a-button block @click="handleSelectAllSteps" size="small">
                      {{ isAllStepsSelected ? 'Unselect all' : 'Select all' }}
                    </a-button>
                  </a-col>
                </a-row>
              </a-col>

              <a-col v-if="showCompareAnalytics" span="12">
                <a-range-picker
                  class="w-100"
                  id="analytics-trace-date-range-filter"
                  :value="dateRangeForComparison"
                  show-time
                  :placeholder="[
                    'Start date (yyyy-mm-dd)',
                    'End date (yyyy-mm-dd)',
                  ]"
                  :get-popup-container="getPopupContainer"
                  @change="setDateRangeForComparison"
                />
              </a-col>

              <a-col v-if="showCompareAnalytics" span="12">
                <a-select
                  id="analytics-trace-select-device-filter-for-comparison"
                  v-model:value="selectedDevicesForComparison"
                  mode="multiple"
                  class="w-100"
                  placeholder="Select Device(s) For Comparison"
                  :options="compareDeviceOptions"
                  :loading="isFetchingDevices"
                  :filter-option="filterOption"
                  :get-popup-container="getPopupContainer"
                  :max-tag-count="1"
                />
              </a-col>

              <a-col
                v-if="showCompareAnalytics"
                class="text-end d-flex"
                span="6"
              >
                <a-button
                  type="primary"
                  block
                  :loading="isFetchingAnalyticsForComparison"
                  @click="getDataForComparison"
                >
                  Compare
                </a-button>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-card>
    </a-col>

    <a-col span="24">
      <a-row :gutter="[8, 16]">
        <!-- Date Range Picker For Comparison -->
        <!-- <a-col v-if="showCompareAnalytics" span="12">
          <a-range-picker
            class="w-100"
            id="analytics-trace-date-range-filter"
            :value="dateRangeForComparison"
            show-time
            :placeholder="['Start date (yyyy-mm-dd)', 'End date (yyyy-mm-dd)']"
            :get-popup-container="getPopupContainer"
            @change="setDateRangeForComparison"
          />
        </a-col> -->

        <!-- Compare/Close Button-->
        <!-- <a-col
          :span="showCompareAnalytics ? 8 : 6"
          :offset="showCompareAnalytics ? 4 : 18"
          align="end"
        >
          <a-button
            v-if="!isNoDataAvailable && isAnalytics"
            block
            @click="setShowCompareAnalytics(!showCompareAnalytics)"
          >
            {{ showCompareAnalytics ? 'Close Comparison' : 'Compare' }}
            <template #icon>
              <CloseCircleOutlined v-if="showCompareAnalytics" />
              <DiffOutlined v-else />
            </template>
          </a-button>
        </a-col> -->

        <!-- Devices Selector For Comparison -->
        <!-- <a-col v-if="showCompareAnalytics" span="12">
          <a-select
            id="analytics-trace-select-device-filter-for-comparison"
            v-model:value="selectedDevicesForComparison"
            mode="multiple"
            class="w-100"
            placeholder="Select Device(s) For Comparison"
            :options="compareDeviceOptions"
            :loading="isFetchingDevices"
            :filter-option="filterOption"
            :get-popup-container="getPopupContainer"
            :max-tag-count="1"
          />
        </a-col> -->

        <!-- Devices Select All Button For Comparison-->
        <!-- <a-col v-if="showCompareAnalytics" span="6">
          <a-button block @click="handleSelectAllComparisonDevices">
            {{ isAllCompareDeviceSelected ? 'Unselect all' : 'Select all' }}
          </a-button>
        </a-col> -->

        <!-- Compare & Close Buttons for Comparison -->
        <!-- <a-col v-if="showCompareAnalytics" class="text-end d-flex" span="6">
          <a-button
            type="primary"
            block
            :loading="isFetchingAnalyticsForComparison"
            @click="getDataForComparison"
          >
            Compare
          </a-button>
        </a-col> -->
      </a-row>
    </a-col>
  </a-row>
</template>
<script>
import {
  ClockCircleOutlined,
  CloseCircleOutlined,
  DiffOutlined,
} from '@ant-design/icons-vue';
import dayjs from 'dayjs';
import { chartsIndicesForSecondDateRange } from 'src/config/charts-config';
import { dateTimeFormat } from 'src/config/date-format-config.js';
import spaceMixin from 'src/mixins/handleSpace';
import { getSortedDevice } from 'src/utils/device';
import { getSortedTask } from 'src/utils/task';
import { mapActions, mapGetters, mapState } from 'vuex';
import dateHelper from '../Helpers/dateHelper';
import getTime from '../Helpers/getTime';
import populateFilters from '../Helpers/populateFilters';
import updateUrl from '../Helpers/updateUrl';

export default {
  components: { CloseCircleOutlined, ClockCircleOutlined, DiffOutlined },
  mixins: [spaceMixin],
  inject: ['toast'],
  props: {
    showCycleInput: { type: Boolean, required: true, default: false },
    showDurationInput: { type: Boolean, required: true, default: false },
    showStepsFilter: { type: Boolean, required: true, default: false },
    isNoDataAvailable: { type: Boolean, required: true, default: false },
    isAnalytics: { type: Boolean, required: true, default: false },
  },
  emits: ['change', 'updateCards', 'searchDocs', 'changePage'],
  data() {
    return {
      selectTaskDevice: [],
      fetchingTasks: false,
      isAllTagsSelected: false,
      selectedTags: [],
      organizationTagOptions: [],
      showFilters: false,
      dateRange: [null, null],
      selectedDevices: [],
      selectedDevicesForComparison: [],
      selectedSteps: [],
      minCycleTime: 0,
      maxCycleTime: 0,
      cyclesFromParams: null,
      durationStartTime: null,
      durationEndTime: null,
      selectedMetaData: null,
      MetaDataOptions: [],
      metaDataValue: null,

      containerHeight: 'auto',
    };
  },

  computed: {
    ...mapGetters([
      'organizationTags',
      'selectedDeviceTasks',
      'taskCreatedAt',
      'isFetchingCycles',
      'organization',
      'isFetchingTasks',
      'allTasks',
      'selectedTask',
      'isFetchingDevices',
      'isFetchingCycles',
      'devices',
      'cycleTypes',
      'selectedCycleType',
      'deviceSerialNumToDisplayNameMap',
      'deviceDisplayNameToSerialNumMap',
      'negativeSteps',
      'optionalSteps',
      'taskProcesses',
      'stepsToIndexMapping',
      'virtualTasks',
      'selectedVirtualTask',
      'showCompareAnalytics',
      'dateRangeForComparison',
      'isFetchingAnalyticsForComparison',
      'isFetchingMetaData',
      'metaData',
    ]),

    taskOptions() {
      return getSortedTask(this.allTasks);
    },

    deviceOptions() {
      const filteredDevices = this.devices?.filter(
        (device) =>
          !this.selectedDevices.includes(
            device.display_name || device.Serial_number
          ) && !!device.Serial_number
      );
      // if (this.selectTaskDevice.length) {
      //   let filteredDevices = [];
      //   let sortedDevices = getSortedDevice(this.devices);
      //   let selectedCycleMapping = {};

      //   this.selectTaskDevice.forEach((device) => {
      //     selectedCycleMapping[device] = 1;
      //   });

      //   sortedDevices.forEach((device) => {
      //     if (selectedCycleMapping[device.value] == 1) {
      //       filteredDevices.push(device);
      //     }
      //   });

      //   // return filteredDevices;
      //   return this.removeDuplicates(filteredDevices);
      // }
      return getSortedDevice(filteredDevices);
    },

    metaDataOptions() {
      return this.metaData?.map((item) => ({
        label: item.key,
        value: item.key,
      }));
    },

    compareDeviceOptions() {
      const filteredDevices = this.devices?.filter(
        (device) =>
          !this.selectedDevicesForComparison.includes(
            device.display_name || device.Serial_number
          ) && !!device.Serial_number
      );
      return getSortedDevice(filteredDevices);
    },

    cycleTypeOptions() {
      return Object.entries(this.cycleTypes).map(([key, value]) => ({
        value: value,
        label: key,
      }));
    },

    isDevicesSelected() {
      return this.selectedDevices.length;
    },

    isAllCompareDeviceSelected() {
      return this.selectedDevicesForComparison.length === this.devices.length;
    },

    isAllStepsSelected() {
      return (
        this.selectedSteps.length ===
        this.stepFilterOptions?.filter(
          (option) => !this.isNegativeOrOptionalStep(option.value)
        ).length
      );
    },

    stepFilterOptions() {
      if (!this.taskProcesses.length) return;
      let subSteps = [];
      this.taskProcesses.forEach((process) => {
        const processSubSteps = process.steps.reduce(
          (res, el) => [...res, ...el.substeps],
          []
        );
        subSteps = [...subSteps, ...processSubSteps];
      });
      return subSteps.map((step) => {
        let option = {
          value: this.stepsToIndexMapping[step],
          label: step,
        };
        option['disabled'] = this.isNegativeOrOptionalStep(option.value);
        if (this.negativeSteps.includes(option.value)) {
          option['label'] = option['label'] + '  -  ' + '(negative)';
          option['class'] = 'text-secondary';
        }
        if (this.optionalSteps.includes(option.value)) {
          option['label'] = option['label'] + '  -  ' + '(optional)';
          option['class'] = 'text-secondary';
        }
        return option;
      });
    },
  },

  watch: {
    organizationTags: {
      handler(value) {
        this.organizationTagOptions = value.map((tag) => {
          return {
            value: tag.id,
            label: tag.tag_name,
          };
        });
      },
      deepClone: true,
    },
    taskCreatedAt(value) {
      if (!value) return;
      this.handleSelectDateRange(2);
      // this.dateRange = [
      //   dateHelper._getDate(new Date(value)),
      //   dateHelper._getDate(),
      // ];
    },

    selectedDeviceTasks: {
      handler(value) {
        // this.selectTaskDevice = value.map(
        //   (data) => data.display_name || data.serial_number
        // );
        this.selectedDevices = value.map(
          (data) => data.display_name || data.serial_number
        );
      },
      deepClone: true,
    },

    cyclesFromParams(value) {
      if (!value || !Array.isArray(value)) return;
      const data = this.getCardsData();
      this.$emit('updateCards', data);
    },

    async selectedTask(value, oldValue) {
      this.fetchingTasks = true;
      if (!value) return;
      await this.fetchTaskDetails(value);
      await this.fetchVirtualTasks(value);
      //
      const filterContainer = this.$refs.filterContainer;
      if (this.showFilters) {
        filterContainer.style.height = `${filterContainer.scrollHeight}px`;
      } else {
        filterContainer.style.height = '25px';
      }
      this.selectedMetaData = null;
      this.setMetaData({
        organization: this.organization,
        task: this.selectedTask,
      });
      try {
        if (!(this.selectedDevices.length && oldValue === null)) {
          await this.getTaskDevices(value);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.fetchingTasks = false;
      }
    },

    selectedVirtualTask(value) {
      if (value) {
        this.fetchVirtualTaskDetails(value);
      } else {
        this.resetVirtualTaskStates();
      }
    },

    async devices() {
      await populateFilters.apply(this);
    },

    taskProcesses() {
      this.setIndexToStepsMapping();
      this.setStepsToIndexMapping();
      this.setStepToAttributesMapping();
    },

    showCompareAnalytics(value) {
      if (!value) {
        const newQueryParam = { ...this.$route.query };
        delete newQueryParam['c_start_date'];
        delete newQueryParam['c_end_date'];
        delete newQueryParam['c_devices'];
        this.selectedDevicesForComparison = [];
        this.setDateRangeForComparison([
          dateHelper._getDate(),
          dateHelper._getDate(),
        ]);
        this.$router.replace({
          name: this.$route.name,
          query: newQueryParam,
        });

        const filterContainer = this.$refs.filterContainer;

        if (this.showFilters) {
          filterContainer.style.height = `100px`;
        }
      } else {
        const filterContainer = this.$refs.filterContainer;

        if (this.showFilters) {
          filterContainer.style.height = `225px`;
        }
      }
    },
  },

  mounted() {
    this.$refs.filterContainer.style.height = '25px';
  },

  created() {
    this.clearTaskDetails();
    this.getAllTasks();
    this.getOrgDevices(this.organization);
    if (this.selectedTask) {
      this.setIndexToStepsMapping();
      this.setStepsToIndexMapping();
    }
    this.fetchOrganizationTags();
    this.emitter.on('getAnalyticsAndTrace', this.getData);
  },

  beforeUnmount() {
    this.setSelectedTask(null);
    this.resetTaskCreatedAt();
    this.dateRange = [null, null];
  },

  methods: {
    ...mapState(['isFetchingMetaData']),
    ...mapActions([
      'clearTaskDetails',
      'setIsMissedStepsSelected',
      'fetchOrganizationTags',
      'resetTaskCreatedAt',
      'getAllTasks',
      'fetchTaskDetails',
      'getOrgDevices',
      'setSelectedTask',
      'setSelectedCycleType',
      'setIndexToStepsMapping',
      'setStepsToIndexMapping',
      'setStepToAttributesMapping',
      'fetchVirtualTasks',
      'getTaskDevices',
      'fetchVirtualTaskDetails',
      'resetVirtualTaskStates',
      'setSelectedVirtualTask',
      'setShowCompareAnalytics',
      'setDateRangeForComparison',
      'setMetaData',
    ]),

    comparingAnalytics() {
      this.setShowCompareAnalytics(!this.showCompareAnalytics);
    },

    handleSelectDateRange(rangeType) {
      if (rangeType == 1) {
        this.dateRange = [
          dateHelper.subtractDays(new Date(), 7), // Subtract 7 days from today
          dateHelper._getDate(new Date()), // Get today's date with timezone adjustment
        ];
      } else if (rangeType == 2) {
        this.dateRange = [
          dateHelper.getTodayStartTime(), // todays start time
          dateHelper._getDate(new Date()), // Get today's date with timezone adjustment
        ];
      } else if (rangeType == 3) {
        this.dateRange = [
          dateHelper.subtractMonths(new Date(), 1), // Subtract  1 month from today
          dateHelper._getDate(new Date()), // Get today's date with timezone adjustment
        ];
      }
    },

    removeDuplicates(arr) {
      const uniqueValues = new Map();
      arr.forEach((item) => {
        uniqueValues.set(item.value, item);
      });
      return Array.from(uniqueValues.values());
    },

    adjustContainerHeight() {
      const container = this.$refs.filterContainer;
      const advancedFilters = this.$refs.advancedFilters;
      const mainFiltersHeight = container.scrollHeight;
      const advancedFiltersHeight = advancedFilters
        ? advancedFilters.scrollHeight
        : 0;

      if (this.showFilters || this.showCompareAnalytics) {
        container.style.height = `${
          mainFiltersHeight + advancedFiltersHeight
        }px`;
      } else {
        container.style.height = `${mainFiltersHeight}px`;
      }
    },

    toggleAdvanceFilter() {
      this.showFilters = !this.showFilters;
      const filterContainer = this.$refs.filterContainer;
      if (this.showFilters) {
        filterContainer.style.height = `${filterContainer.scrollHeight}px`;
      } else {
        filterContainer.style.height = '25px';
      }
    },

    // toggleAdvanceFilter() {
    //   this.showFilters = !this.showFilters;
    // },

    disabledStartDate(current) {
      return (
        (current &&
          this.getDateTime(current) <
            this.getDateTime(
              dateHelper._getDate(new Date(this.taskCreatedAt))
            )) ||
        (current &&
          this.getDateTime(current) > this.getDateTime(this.dateRange[1]))
      );
    },

    disabledEndDate(current) {
      return (
        (current &&
          this.getDateTime(current) < this.getDateTime(this.dateRange[0])) ||
        (current && this.getDateTime(current) > this.getDateTime(new Date()))
      );
    },

    getPopupContainer(trigger) {
      return trigger.parentNode;
    },

    handleTagSelect(val) {
      if (val !== this.selectedTags) this.selectedTags = [];
      this.selectedTags = val;
      // this.$refs.analytics_trace_task_select.blur();
    },

    handleTaskSelect(val) {
      if (val !== this.selectedTask) this.selectedSteps = [];
      this.setSelectedTask(val);
      this.$refs.analytics_trace_task_select.blur();
    },

    getVideoS3Details(video, isPredictionFile = false) {
      const { fileName, device_id } = video;
      const bucket = `${this.organization}-videos`;
      let filePath = `${device_id}/${this.selectedTask}/Processed/${fileName}`;
      if (isPredictionFile) filePath += '_preds.json';
      return { bucket, filePath };
    },

    checkDuration() {
      let notValid = false;
      if (this.durationEndTime && this.durationStartTime) {
        notValid =
          parseInt(this.durationEndTime) <= parseInt(this.durationStartTime)
            ? true
            : false;
      }
      return notValid;
    },

    getPayload() {
      const data = {
        organization: this.organization,
        task: this.selectedTask,
        tags: JSON.stringify(this.selectedTags.map((data) => data)),
        devices: this.getSelectedDevicesForURL(),
        datetime_start: this.getDateTime(this.dateRange[0]),
        datetime_end: this.getDateTime(this.dateRange[1]),
        cyclesFromParams: this.cyclesFromParams,
        cycle_type: this.selectedCycleType,
      };
      if (!this.selectedTask) {
        this.toast.info('Task is not selected');
        return false;
      }

      if (
        this.getDateTime(this.dateRange[0]) >
        this.getDateTime(this.dateRange[1])
      ) {
        this.toast.info('End date should not be before start date.');
        return false;
      }

      if (this.selectedDevices.length === 0) {
        this.toast.info('Device is not selected');
        return false;
      }

      if (
        (this.durationStartTime && !this.durationEndTime) ||
        (!this.durationStartTime && this.durationEndTime)
      ) {
        this.toast.info('Duration must have both start and end time');
        return false;
      }

      if (this.checkDuration()) {
        this.toast.info(
          'In Duration, End time should be greater than start time!'
        );
        return false;
      }

      if (this.durationStartTime) data['start_time'] = this.durationStartTime;
      if (this.durationEndTime) data['end_time'] = this.durationEndTime;
      if (this.minCycleTime) data['min_cycle_time_in_bin'] = this.minCycleTime;
      if (this.maxCycleTime) data['max_cycle_time_in_bin'] = this.maxCycleTime;
      if (this.selectedVirtualTask)
        data['virtualTask'] = this.selectedVirtualTask;
      if (this.selectedSteps.length) {
        data['missed_steps'] = this.getSelectedStepData();
        this.setIsMissedStepsSelected(true);
      } else {
        this.setIsMissedStepsSelected(false);
      }

      if (this.showCompareAnalytics) {
        this.setStartEndDateForComparison(data);
        if (this.selectedDevicesForComparison) {
          data['c_devices'] = this.getSelectedCompareDevicesForURL();
        }
      }
      if (this.selectedMetaData && this.metaDataValue) {
        data['meta_value'] = this.metaDataValue;
        data['meta_key'] = this.selectedMetaData;
      }
      return data;
    },

    setStartEndDateForComparison(data) {
      const [startDate, endDate] = this.dateRangeForComparison;
      const [_startDate, _endDate] = [
        this.getDateTime(startDate),
        this.getDateTime(endDate),
      ];
      data['c_start_date'] = _startDate;
      data['c_end_date'] = _endDate;
    },

    getData(isTraceOnly = false, callBack = () => {}) {
      const data = this.getPayload();
      !this.isKaizenAdvisor && updateUrl.apply(this, [data]);
      data['task'] = this.selectedVirtualTask || this.selectedTask;
      if (!isTraceOnly) this.$emit('changePage', 1);
      this.$emit('change', data, isTraceOnly, callBack);
    },

    getDataForComparison() {
      const data = this.getPayload();
      this.setStartEndDateForComparison(data);
      !this.isKaizenAdvisor && updateUrl.apply(this, [data]);
      data['task'] = this.selectedVirtualTask || this.selectedTask;
      data['datetime_start'] = data['c_start_date'];
      data['datetime_end'] = data['c_end_date'];
      data['devices'] = data['c_devices'];
      data['isComparing'] = true;
      this.$emit('change', data);
      return data;
    },

    getDateTime(date) {
      return `${dayjs(date).format(dateTimeFormat)}`;
    },

    redirectToPage(name, query = {}, chartIndex) {
      let key = 'dateRange';
      if (this.showCompareAnalytics) {
        key = chartsIndicesForSecondDateRange.includes(chartIndex)
          ? 'dateRangeForComparison'
          : key;
      }
      const [startDate, endDate] = this[key];

      query = {
        ...query,
        taskId: this.selectedTask,
        deviceId: this.getSelectedDevicesForURL(),
        startTime: this.getDateTime(startDate),
        endTime: this.getDateTime(endDate),
      };

      let { href } = this.$router.resolve({ name, query });
      window.open(href, '_blank');
    },

    getSelectedDevicesForURL() {
      return JSON.stringify(
        this.selectedDevices.map((d) => this.deviceDisplayNameToSerialNumMap[d])
      );
    },
    getSelectedCompareDevicesForURL() {
      return JSON.stringify(
        this.selectedDevicesForComparison.map(
          (d) => this.deviceDisplayNameToSerialNumMap[d]
        )
      );
    },

    getSumOfCycles() {
      return this.cyclesFromParams.reduce((res, el) => res + el);
    },

    getAverageCycleTime(sumOfCyclesTime, cyclesCount) {
      const averageCycleTime = sumOfCyclesTime / cyclesCount;
      return getTime(averageCycleTime * 1000);
    },

    getSelectedStepData() {
      const paramsSteps = this.selectedSteps.map((ix) => ix);
      return JSON.stringify(paramsSteps);
    },

    isNegativeOrOptionalStep(index) {
      return (
        this.negativeSteps?.includes(index) ||
        this.optionalSteps?.includes(index)
      );
    },

    getCardsData() {
      const sum_of_cycles_time = this.getSumOfCycles();
      const number_of_cycles = this.cyclesFromParams.length;
      const total_cycles_time = getTime(sum_of_cycles_time * 1000);
      const average_cycle_time = this.getAverageCycleTime(
        sum_of_cycles_time,
        number_of_cycles
      );

      return {
        number_of_cycles,
        average_cycle_time,
        total_cycles_time,
        total_inference_time: total_cycles_time,
      };
    },

    handleSelectAll() {
      if (this.isDevicesSelected) {
        this.selectedDevices = [];
        return;
      }
      // if (this.selectTaskDevice.length) {
      //   this.selectedDevices = this.selectTaskDevice;
      //   return;
      // }
      this.selectedDevices = this.devices.map(
        (d) => d.display_name || d.Serial_number
      );
    },
    handleSelectAllComparisonDevices() {
      if (this.isAllCompareDeviceSelected) {
        this.selectedDevicesForComparison = [];
        return;
      }
      this.selectedDevicesForComparison = this.devices.map(
        (d) => d.display_name || d.Serial_number
      );
    },

    handleSelectAllTags() {
      if (this.isAllTagsSelected) {
        this.selectedTags = [];
        this.isAllTagsSelected = false;
        return;
      }
      this.selectedTags = this.organizationTags?.map((step) => step.value);
      this.isAllTagsSelected = true;
    },

    handleSelectAllSteps() {
      if (this.isAllStepsSelected) {
        this.selectedSteps = [];
        return;
      }
      this.selectedSteps = this.stepFilterOptions
        ?.map((step) => step.value)
        .filter((index) => !this.isNegativeOrOptionalStep(index));
    },

    handleVirtualTaskChange(e) {
      const { value } = e.target;
      this.setSelectedVirtualTask(value);
    },
  },
};
</script>
<style scoped>
.advance-filter-date-range-footer {
  display: flex;
  align-items: center;
}

.hidden {
  visibility: hidden;
}
.filter-card {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.w-100 {
  width: 100%;
}

.d-flex {
  display: flex;
}

.justify-content-end {
  justify-content: flex-end;
}

.filter-container {
  /* overflow: hidden; */
  height: 25px;
  transition: height 0.5s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 1;
}

.filter-container.expanded {
  opacity: 1;
}

.advanced-filters {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.filter-container.expanded .advanced-filters {
  opacity: 1;
}

.dependent-filters {
  transition: opacity 1s ease-in;
}

@keyframes fadeExpand {
  0% {
    height: 25px;
    opacity: 0;
  }
  100% {
    height: auto;
    opacity: 1;
  }
}
</style>
